.about{
    font-size: 2.5rem;
    font-weight: 700;
    color: #0C1923;
}

.moi{
    color: #3B82F6;
}

.aboutText{
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-top: 1.5rem;
}

.aboutContainer{
    padding-left: 4rem;
    padding-right: 4rem;
}

.profileImage{
    max-width: 200px;
    height: auto;
    border-radius: 50%;
    margin-top: 1rem;
}