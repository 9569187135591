.portFolioTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0C1923;
}

.portfolio {
  padding: 2rem 4rem;
}

.competences {
  color: #3B82F6;
}

.portFolio-item {
  margin: 3rem 0;
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {

  .portFolio-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
  }

  .concreteImg {
    max-width: 100%;
    max-height: 200px; /* Ajustez la valeur en fonction de la hauteur souhaitée */
  }

  .portFolio-content img {
    max-width: 100%;
    height: auto;
  }

  .portFolio-text {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title-item {
  color: #3B82F6;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .portFolio-text {
    padding-top: 2rem;
  }
}

.techno {
  color: gray;
}

.portFolio-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 1.7rem;
}

.portFolio-link:hover {
  color: #0C1923;
}

.portFolio-content a:hover img {
  transform: scale(1.05);
  /* Agrandissement de 5% au survol */
  transition: transform 0.3s ease;
  /* Transition fluide */
}

.portFolio-content a img {
  max-width: 100%;
}

.concreteImg {
  max-width: 100%;
  max-height: 200px; 
  display: block; /* Assurez-vous que l'image est affichée comme un bloc */
  margin-left: auto; /* Centre l'image horizontalement */
  margin-right: auto;
}

.portFolio-content{
  margin-top: 1rem;
}