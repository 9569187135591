@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.desktopMenuListItem {
    font-family: 'Noto' sans-serif;
    text-decoration: none;
    color: #0C1923;
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover{
    color: #3B82F6;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid;
}

.navbar{
    background-color: #FCFCFC;
    height: 5rem;
    width: 1OOvw;
    max-width: 85rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.prenom{
    color: #0C1923;
}

.nom{
    color: #3B82F6;
}

.logo {
    font-family: 'Noto' sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}

.socialMedia img{
    width: 40px;
    height: auto;
}

@media screen and (max-width:776px){
    .desktopMenu{
        display: none;
    }
    .socialMedia{
        display: none;
    }
    
}

.active{
    color: #3B82F6;
    border-bottom: 1px solid #3B82F6;
    padding-bottom: 0.5rem;
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background-color: #f5f5f5;
    border-radius: 1rem;
}

.ListItem{
    font-family: 'Noto' sans-serif;
    text-decoration: none;
    color: #0C1923;
    margin: 1rem;
    cursor: pointer;
}

@media screen and (max-width:776px){
    .mobMenu{
        display: flex;
    }
}

