.footer{
    width: 100vw;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}

.footer img{
    width: 40px;
}