.skillsTitle{
    font-size: 2.5rem;
    font-weight: 700;
    color: #0C1923;
}

.skills{
    padding: 4rem 4rem;
}

.competences{
    color: #3B82F6;
}

.skills-container {
    display: flex;
    justify-content: space-between;
}

.skills-item {
    width: 45%;
    margin: 1.5%;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.skills-header {
    border-bottom: 1px solid #3B82F6;
    padding-bottom: 10px;
    
}

.skills-list {
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; 
    list-style-type: none;
}

.skillsH{
    font-size: 1.5rem;
    color: #3B82F6;
}

.skillListImg{
    height: 40px;
    width: auto;
}

.skills-list p{
    font-size: 1rem;
    font-weight: 600;
}

@media screen and (max-width: 900px) {
    .skills-container {
      flex-direction: column; 
    }
  
    .skills-item {
      width: 100%; 
      margin: 10px 0; 
    }
  }
  
