@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.introContent {
    height: calc(100vh - 4rem);
    width: 100%;
    overflow: hidden;
    padding: 8rem;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    margin: 0 auto;
}

.name{
    font-family: 'Noto' sans-serif;
    font-weight: 800;
    font-size: 3.75rem;
    line-height: 1rem;
}

.title{
    font-family: 'Noto' sans-serif;
    font-weight: 800;
    font-size: 3.75rem;
    color: #3B82F6;
    line-height: 1.3;
}

.introPar{
    font-family: 'Noto' sans-serif;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 300;
    margin-top: 1.5rem;
}

.btnContact{
    font-family: 'Noto' sans-serif;
    background-color: #2A5778;
    color: #FCFCFC;
    border: none;
    border-radius: 0.8rem;
    padding: 1rem 2rem;
}


@media screen and (max-width: 768px) {
    .introContent {
        padding: 1rem; /* Ajustement du padding pour les petits écrans */
    }

    .name, .title {
        font-size: 2.5rem; /* Ajustement de la taille de la police pour les petits écrans */
    }

    .introPar {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 308px) {
    
    .name {
        line-height: 3rem;
    }

}

.introContent button:hover {
    transform: scale(1.05);
    /* Agrandissement de 5% au survol */
    transition: transform 0.3s ease;
    /* Transition fluide */
    background-color: #3B82F6;
  }
