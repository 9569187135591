* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
    font-family: 'Noto' sans-serif;
}

body {
    background: #FCFCFC;
    color: #0C1923;
    overflow: hidden;
    overflow-y: scroll;
}



