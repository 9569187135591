.contact{
    padding: 3rem;

}

.contactTitle{
    font-size: 2.5rem;
    font-weight: 700;
    color: #0C1923;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
}

.nameForm, .email, .msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 1rem;
}

.contact button:hover {
    transform: scale(1.05);
    /* Agrandissement de 5% au survol */
    transition: transform 0.3s ease;
    /* Transition fluide */
    background-color: #3B82F6;
  }

